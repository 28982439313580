<template>
  <van-tabbar
    v-model="activeNow"
    active-color="#e143ac"
    :border="false"
  >
    <van-tabbar-item v-for="(item, index) in list" :key="index" :to="item.to">
      <div class="item">
        <img
          :src="active !== item.to ? item.icon : item.activeIcon"
          style="width:24px"
        />
        <span
          :style="{ color: active === item.to ? '#e143ac' : null }"
          >{{ item.name }}</span
        >
      </div>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    active: {
      type: String,
      default: 'Home'
    }
  },
  computed: {
    ...mapGetters(['lang', 'basicConfig'])
  },
  watch: {
    active(newVal) {
      this.activeNow = newVal
    },
    lang() {
      this.changeLang()
    }
  },
  data() {
    return {
      activeNow: 'Home',
      list: []
    }
  },
  mounted() {
    console.log(this.basicConfig)
    this.activeNow = this.active
    this.changeLang()
  },
  methods: {
    changeLang() {
      this.list = []
      this.list.push({
        name: this.$t('tabBar').home,
        icon: require('../assets/new/index0.png'),
        activeIcon: require('../assets/new/index1.png'),
        to: 'Home'
      })
      this.list.push({
        name: this.$t('tabBar').Quotation,
        icon: require('../assets/new/hang0.png'),
        activeIcon: require('../assets/new/hang1.png'),
        to: 'Quotation'
      })
      if (this.basicConfig.IsOpenCoinTrade) {
        this.list.push({
          name: this.$t('tabBar').Trade,
          icon: require('../assets/new/trade0.png'),
          activeIcon: require('../assets/new/trade1.png'),
          to: 'Trade'
        })
      }
      this.list.push({
        name: this.$t('tabBar').Contract,
        icon: require('../assets/new/gang0.png'),
        activeIcon: require('../assets/new/gang1.png'),
        to: 'Product'
      })
      this.list.push({
        name: this.$t('tabBar').assets,
        icon: require('../assets/new/mine0.png'),
        activeIcon: require('../assets/new/mine1.png'),
        to: 'Property'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.van-tabbar
  border-top 1px solid #122034
  background: #17181e;
  >>> .van-tabbar-item--active
    background: none;
  .item
    display flex
    flex-direction column
    justify-content center
    align-items center
    img
      margin-bottom 4px
      width 24px
      height 24px
    span
      text-align: center
      white-space: nowrap
</style>
