var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"nav"},[_c('div',{class:{ activeNav: _vm.activeNav === 0 },on:{"click":function($event){return _vm.handleClick(0)}}},[_vm._v(" "+_vm._s(_vm.$t('property').Contract)+" ")]),_c('div',{class:{ activeNav: _vm.activeNav === 1 },on:{"click":function($event){return _vm.handleClick(1)}}},[_vm._v(" "+_vm._s(_vm.$t('property').Exchange)+" ")])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"uid"},[_vm._v(_vm._s(_vm.$t('home').UserName)+": "+_vm._s(_vm.userInfo.UserName))]),_c('div',{staticClass:"title"},[_c('div',[_vm._v(_vm._s(_vm.$t('property').zzc)+"（$）")])]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.allAmount.toFixed(2)))]),_c('div',{staticClass:"title"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.activeNav === 0 ? _vm.$t('property').bbzh : _vm.$t('property').mhyzh))]),_vm._v(" "+_vm._s(_vm.$t('property').zzc)+"（$） ")])]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.Amount.toFixed(2)))])]),_c('div',{staticClass:"list"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){_vm.$router.push({
          name: 'Account',
          query: {
            info: JSON.stringify(item),
            type: _vm.activeNav,
            ProductName: item.ProductName,
            ProductId: item.ProductId
          }
        })}}},[_c('div',{staticClass:"top"},[_c('div',[_vm._v(_vm._s(item.ProductName))]),_c('van-icon',{attrs:{"name":"arrow","color":"#2e4159"}})],1),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"mItem"},[_c('p',[_vm._v(_vm._s(_vm.$t('property').available))]),_c('p',[_vm._v(_vm._s(item.Balance))])]),_c('div',{staticClass:"mItem"},[_c('p',[_vm._v(_vm._s(_vm.$t('property').OnOrders))]),_c('p',[_vm._v(_vm._s(item.Frozen))])]),_c('div',{staticClass:"mItem"},[_c('p',[_vm._v(_vm._s(_vm.$t('property').Estimated)+"($)")]),_c('p',[_vm._v(_vm._s(item.Convert))])])])])}),0),_c('TabBar',{attrs:{"active":_vm.active}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }