<template>
  <div class="container">
    <div class="nav">
      <div :class="{ activeNav: activeNav === 0 }" @click="handleClick(0)">
        {{ $t('property').Contract }}
      </div>
      <div :class="{ activeNav: activeNav === 1 }" @click="handleClick(1)">
        {{ $t('property').Exchange }}
      </div>
    </div>
    <div class="info">
      <div class="uid">{{ $t('home').UserName }}: {{ userInfo.UserName }}</div>
      <div class="title">
        <div>{{ $t('property').zzc }}（$）</div>
        <!-- <img src="@/assets/new/eye.png" alt="" /> -->
      </div>
      <div class="num">{{ allAmount.toFixed(2) }}</div>
      <div class="title">
        <div>
          <span>{{
            activeNav === 0 ? $t('property').bbzh : $t('property').mhyzh
          }}</span>
          {{ $t('property').zzc }}（$）
        </div>
      </div>
      <div class="num">{{ Amount.toFixed(2) }}</div>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="
          $router.push({
            name: 'Account',
            query: {
              info: JSON.stringify(item),
              type: activeNav,
              ProductName: item.ProductName,
              ProductId: item.ProductId
            }
          })
        "
      >
        <div class="top">
          <div>{{ item.ProductName }}</div>
          <van-icon name="arrow" color="#2e4159" />
        </div>
        <div class="bottom">
          <div class="mItem">
            <p>{{ $t('property').available }}</p>
            <p>{{ item.Balance }}</p>
          </div>
          <div class="mItem">
            <p>{{ $t('property').OnOrders }}</p>
            <p>{{ item.Frozen }}</p>
          </div>
          <div class="mItem">
            <p>{{ $t('property').Estimated }}($)</p>
            <p>{{ item.Convert }}</p>
          </div>
        </div>
      </div>
    </div>
    <TabBar :active="active" />
  </div>
</template>

<script>
import TabBar from '@/components/tabBarNew'
import userApi from '@/api/user'
export default {
  components: {
    TabBar
  },
  data() {
    return {
      activeNav: 1,
      active: 'Property',
      list: [],
      userInfo: {}
    }
  },
  computed: {
    Amount() {
      if (this.activeNav) {
        return this.LegalAmount
      } else {
        return this.CoinAmount
      }
    },
    allAmount() {
      return this.LegalAmount + this.CoinAmount
    },
    LegalAmount() {
      if (this.userInfo.LegalAmountInfo) {
        const LegalAmountInfo = this.userInfo.LegalAmountInfo
        return (
          Number(LegalAmountInfo.BTCConvert) +
          Number(LegalAmountInfo.ETHConvert) +
          Number(LegalAmountInfo.USDT)
        )
      } else {
        return 0
      }
    },
    CoinAmount() {
      if (this.userInfo.CoinAmountInfo) {
        const CoinAmountInfo = this.userInfo.CoinAmountInfo
        let amount = 0
        CoinAmountInfo.forEach(item => {
          amount += Number(item.Convert)
        })
        return amount
      } else {
        return 0
      }
    }
  },
  methods: {
    handleClick(index) {
      this.activeNav = index
      if (index) {
        this.getLegalAmountInfo()
      } else {
        this.getCoinAmountInfo()
      }
    },
    async getUserInfo() {
      this.userInfo = await userApi.userInfo()
      this.getLegalAmountInfo()
    },
    getLegalAmountInfo() {
      const LegalAmountInfo = this.userInfo.LegalAmountInfo
      this.list = [
        {
          ProductName: 'BTC',
          Balance: LegalAmountInfo.BTC,
          Frozen: LegalAmountInfo.BTCFrozen,
          Convert: LegalAmountInfo.BTCConvert
        },
        {
          ProductName: 'ETH',
          Balance: LegalAmountInfo.ETH,
          Frozen: LegalAmountInfo.ETHFrozen,
          Convert: LegalAmountInfo.ETHConvert
        },
        {
          ProductName: 'USDT',
          Balance: LegalAmountInfo.USDT,
          Frozen: LegalAmountInfo.USDTFrozen,
          Convert: LegalAmountInfo.USDTConvert
        }
      ]
    },
    async getCoinAmountInfo() {
      this.list = this.userInfo.CoinAmountInfo
    }
  },
  mounted() {
    this.getUserInfo()
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 50px 0;
  .nav {
    background: #17181e;
    position: fixed;
    left: 0;
    top: 0;
    height: 50px;
    width: 100vw;
    align-items: center;
    display: flex;
    justify-content: center;
    div {
      padding: 0 10px;
      color: #9fbedc;
      font-size: 14px;
    }
    .activeNav {
      color: #fff;
      font-weight: bold;
    }
  }
  .info {
    background: #17181e;
    padding: 15px;
    .uid {
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      margin: 10px 0;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #999;
      margin: 10px 0;
      img {
        width: 15px;
        height: 15px;
      }
      span {
        font-size: 14px;
        margin-right: 10px;
      }
    }
    .num {
      font-size: 16px;
      font-weight: bold;
      margin-top: 14px;
    }
  }
  .list {
    .item {
      margin: 10px;
      border-radius: 4px;
      background: #17181e;
      padding: 12px 10px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: bold;
        color: #e143ac;
      }
      .bottom {
        display: flex;
        .mItem {
          flex: 1;
          color: #fff;
          p:nth-of-type(1) {
            color: #9fbedc;
            margin: 12px 0 7px;
          }
          &:nth-of-type(2) {
            text-align: center;
          }
          &:nth-of-type(3) {
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
